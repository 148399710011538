import { authApi } from "../interceptor/auth.interceptor";

class SnapService {


    static async Snap(queryParams) {
        return await authApi
            .post(`snap/code?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

}

export default SnapService;