import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Grid,
    Select,
    MenuItem, CircularProgress, Switch
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setApprovalAudiences } from '../../redux/slices/audience';
import MenuSideBar from "../../components/common/sidebar/menu-sidebar/MenuSideBar";
import ApproveCard from "../../components/common/card/approve-card/ApproveCard";
import AudienceService from "../../services/api/audience.service";
import {setMessage} from "../../redux/slices/message";
import {setMessageSnackbarStateState} from "../../redux/slices/common";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useForm} from "react-hook-form";
import UserService from "../../services/api/user.service";



const ApproveUserPage = () => {

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const { approvalAudiences } = useSelector((state) => state.audience);
    const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';
    const { register } = useForm({
        mode: 'onChange'
    });
    const navigate = useNavigate();
    const [selectedOptions, setSelectedOptions] = useState({ selectedBrand: "" });
    const [brand, setBrand] = useState(null);

    useEffect(() => {

        fetchData();
        // console.log(isUploaded);
        // Call the fetchData function every 5 seconds

        const intervalId = setInterval(() => {

                fetchData();
        }, 300000);

        return () => clearInterval(intervalId);
    }, [user, selectedCountry, dispatch]);

    const fetchData = () => {
        if (user && selectedCountry) {

            const queryParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
            ];

            AudienceService.getApprovalAudience(queryParams.join('&')).then(
                (data) => {
                    const resultData = data?.results;
                    if (resultData?.length) {
                        dispatch(setApprovalAudiences(resultData));
                        localStorage.setItem("approveClientList", JSON.stringify(resultData));
                    } else {
                        dispatch(setApprovalAudiences([]));
                        localStorage.setItem("approveClientList", JSON.stringify([]));
                    }
                    return Promise.resolve();
                },
                (error) => {
                    // console.log(error?.response?.data?.message);
                    return Promise.reject();
                }
            );

            UserService.getAllClientsDetailsCall(queryParams.join('&')).then(
                (data) => {
                    const resultData = data?.results;
                    if (resultData?.length) {
                        setBrand(resultData);
                    } else {
                        setBrand([]);
                    }
                    return Promise.resolve();
                },
                (error) => {
                    // console.log(error?.response?.data?.message);
                    return Promise.reject();
                }
            );
        }
    }


    const onChangeOption = (newValue, type,event) => {

        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [type]: newValue
        }));

        const queryParams = [
            `userId=${user.id}`,
            `countryId=${selectedCountry.id}`,
        ];

        if(newValue?.id != null) {

            queryParams.push(`companyUserId=${newValue.id}`);
        }

        AudienceService.getApprovalAudience(queryParams.join('&')).then(
            (data) => {
                const resultData = data?.results;
                if (resultData?.length) {
                    dispatch(setApprovalAudiences(resultData));
                } else {
                    dispatch(setApprovalAudiences([]));
                }
                return Promise.resolve();
            },
            (error) => {
                // console.log(error?.response?.data?.message);
                return Promise.reject();
            }
        );
    };


    const handleMouseEnter = (index) => {
        let audienceDummy = [...approvalAudiences];
        audienceDummy = audienceDummy.map((audience, i) => {
            return {
                ...audience,
                hover: index === i ? false : true
            }
        });
        dispatch(setApprovalAudiences(audienceDummy));
    }
    const handleMouseLeave = () => {
        let audienceDummy = [...approvalAudiences];
        audienceDummy = audienceDummy.map((audience) => {
            return {
                ...audience,
                hover: false
            }
        });
        dispatch(setApprovalAudiences(audienceDummy));
    }

    const handleAudienceRename = (audience, audienceId, rename, category, setRenameAudienceModalState, renameAudienceModalState, selectedBrandType, selectedCrumbType) => {
        const queryParams = [
            `id=${audience.id}`
        ]
        const formData = {
            name: rename.trim(),
            categoryList: category.map(item => item.id),
            userId: user.id,
            countryId: selectedCountry.id,
        };

        if (selectedBrandType === undefined || selectedBrandType === null) {
            formData.brandTypeId = null; // You can set it to a default value or null based on your requirements
        } else {
            formData.brandTypeId = selectedBrandType.id;
        }

        if (selectedCrumbType === undefined || selectedCrumbType === null) {
            formData.crumbId = null; // You can set it to a default value or null based on your requirements
        } else {
            formData.crumbId = selectedCrumbType.id;
        }

        // setIsSubmitting(true);
        AudienceService.renameAudience(queryParams.join('&'), formData).then(
            (data) => {
                if (data?.results) {
                    dispatch(setMessage({
                        type: "success",
                        title: data?.message || "Audience data saved successfully",
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                    fetchData();
                    navigate('/approve');
                    // setIsSubmitting(false);
                    // setInput([]);
                }
                dispatch(setRenameAudienceModalState(false));
                return Promise.resolve();
            },
            (error) => {
                dispatch(setMessage({
                    type: "error",
                    title: error?.response?.data?.message || "Audience Name is already taken!",
                }));
                dispatch(setMessageSnackbarStateState(true));
                dispatch(setRenameAudienceModalState(true));
                // setIsSubmitting(false);
                return Promise.reject();
            }
        )
    };



    return (<>
        <Box className="FullArea" sx={{ position: 'absolute', overflowX: 'hidden', height: '100vh', top: 0 }}>
            {/*<MenuSideBar />*/}
            <Box sx={{ display: 'flex', justifyContent: "center", padding: "20px 0" }}>
                <div className="FlexComplete" style={{ width: "30%" }}>
                    <Autocomplete
                        fullWidth
                        options={brand || []}
                        value={selectedOptions.selectedBrand || null}
                        getOptionLabel={(option) => option.companyName}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <div>
                                    <span style={{ marginRight: '8px' }}>{option.companyName}</span>
                                    <span style={{ marginLeft: 'auto', color: '#999', fontSize: '0.8em' }}>
                                Created by {option.email}
                            </span>
                                    <br />
                                    <span style={{ marginLeft: 'auto', color: '#3c4de7', fontSize: '0.8em' }}>
                                Pending cards - {option.audienceCount}
                            </span>
                                </div>
                            </li>
                        )}
                        onChange={(event, newValue) => {
                            onChangeOption(newValue, 'selectedBrand', event);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select the company email"
                                variant="standard"
                                sx={{ my: 2 }}
                                {...register("brand")}
                                disabled={brand && brand.length === 0}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { color: currentThemeColor }, // Set the color of the input text based on theme color
                                    placeholderTextColor: currentThemeColor // Set the color of the placeholder text based on theme color
                                }}
                            />
                        )}
                        paperprops={{
                            style: {
                                maxHeight: '50vh',
                                maxWidth: '50vh',
                                marginTop: '2px',
                                overflowX: 'auto',
                            },
                        }}
                    />
                </div>
            </Box>

            {approvalAudiences?.length > 0 ? (
                <Grid container className="gridContainer" sx={{ paddingLeft: "55px", paddingTop: "80px", overflowX: 'hidden', top: 0, alignContent: 'space-around', gap: "20px", width: "calc(100% - 5px)" }}>
                    {approvalAudiences.slice().sort((a, b) => a.id - b.id).map((audience, index) => (
                        <Grid key={index}>
                            <ApproveCard
                                audience={audience}
                                index={index}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                handleAudienceRename={handleAudienceRename}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)' }}>
                    <Typography variant="h4" style={{ color: currentTheme === 'dark' ? '#ffffff' : undefined }} >
                        No Cards to Approve
                    </Typography>
                </Box>
            )}
        </Box>
    </>);
}

export default ApproveUserPage;