import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    createCrumbModalState: false,
    audienceActivityModalState: false,
    filterPoiModalState: false,
    userManagementModalState: false,
    compareCardsModalState: false,
    compareCardStatusModalState: false,
    renameAudienceModalState: false,
    approveAudienceModalState: false,
    profileSideBarState: false,
    editProfileSideBarState: false,
    changePasswordState: false,
    deleteCrumbConfirmModalState: false,
    deleteCrumbSuccessModalState: false,
    uploadAudienceModalState: false,
    createAudienceModalState: false,
    cloneAudienceModalState: false,
    refreshAudienceModalState: false,
    messageSnackbarState: false,
    countryBackground: false,
    activateCampaignModalState: false,
    activateCompareAudienceCondirmModalstate: false,
    assignAudienceModalState: false,
    mobileMenuBarState: false,
    mobileSearchAreaState: false,
    mobileGeneralStatState: false,
    mobileAudienceDetailsState: false,
    externalUserManagementModalState: false,
    topUpDetailsState: false,
    contactUsState: false,
    metaPlatformDetailsState: false,
    exlPlatformDetailsState:false,
  },
  reducers: {
    setCreateCrumbModalState: (state, action) => {
      state.createCrumbModalState = action.payload;
    },
    setAudienceActivityModalState: (state, action) => {
      state.audienceActivityModalState = action.payload;
    },
    setFilterPoiModalState: (state, action) => {
      state.filterPoiModalState = action.payload;
    },
    setUserManagementModalState: (state, action) => {
      state.userManagementModalState = action.payload;
    },
    setCompareCardsModalState: (state, action) => {
      state.compareCardsModalState = action.payload;
    },
    setCompareStatusModalState: (state, action) => {
      state.compareCardStatusModalState = action.payload;
    },
    setRenameAudienceModalState: (state, action) => {
      state.renameAudienceModalState = action.payload;
    },
    setApproveAudienceModalState: (state, action) => {
      state.approveAudienceModalState = action.payload;
    },
    setProfileSideBarState: (state, action) => {
      state.profileSideBarState = action.payload;
    },
    setEditProfileSideBarState: (state, action) => {
      state.editProfileSideBarState = action.payload;
    },
    setChangePasswordState: (state, action) => {
      state.changePasswordState = action.payload;
    },
    setDeleteCrumbConfirmModalState: (state, action) => {
      state.deleteCrumbConfirmModalState = action.payload;
    },
    setDeleteCrumbSuccessModalState: (state, action) => {
      state.deleteCrumbSuccessModalState = action.payload;
    },
    setUploadAudienceModalState: (state, action) => {
      state.uploadAudienceModalState = action.payload;
    },
    setCreateAudienceModalState: (state, action) => {
      state.createAudienceModalState = action.payload;
    },
    setCloneAudienceModalState: (state, action) => {
      state.cloneAudienceModalState = action.payload;
    },
    setRefreshAudienceModalState: (state, action) => {
      state.refreshAudienceModalState = action.payload;
    },
    setMessageSnackbarStateState: (state, action) => {
      state.messageSnackbarState = action.payload;
    },
    setCountryBackgroundState: (state, action) => {
      state.countryBackground = action.payload;
    },
    setActivateCampaignModalState: (state, action) => {
      state.activateCampaignModalState = action.payload;
    },
    setActivateCompareAudienceConfirmModalState: (state, action) => {
      state.activateCompareAudienceCondirmModalstate = action.payload;
    },
    setAssignAudienceModalState: (state, action) => {
      state.assignAudienceModalState = action.payload;
    },
    setMobileMenuBarState: (state, action) => {
      state.mobileMenuBarState = action.payload;
    },
    setMobileSearchAreaState: (state, action) => {
      state.mobileSearchAreaState = action.payload;
    },
    setMobileGeneralStatState: (state, action) => {
      state.mobileGeneralStatState = action.payload;
    },
    setMobileAudienceDetailsState: (state, action) => {
      state.mobileAudienceDetailsState = action.payload;
    },
    setExternalUserManagementModalState: (state, action) => {
      state.externalUserManagementModalState = action.payload;
    },
    setTopUpDetailsState: (state, action) => {
      state.topUpDetailsState = action.payload;
    },
    setContactUsState: (state, action) => {
      state.contactUsState = action.payload;
    },
    setMetaPlatformDetailsState: (state, action) => {
      state.metaPlatformDetailsState = action.payload;
    },
    setExlPlatformDetailsState: (state, action) => {
      state.exlPlatformDetailsState = action.payload;
    },
  },
});

export const { setCreateCrumbModalState, setAudienceActivityModalState, setRenameAudienceModalState, setApproveAudienceModalState, setProfileSideBarState, setEditProfileSideBarState, setChangePasswordState, setDeleteCrumbConfirmModalState, setDeleteCrumbSuccessModalState, setUploadAudienceModalState, setMessageSnackbarStateState, setCreateAudienceModalState, setCloneAudienceModalState, setRefreshAudienceModalState, setCountryBackgroundState, setActivateCampaignModalState, setFilterPoiModalState, setUserManagementModalState, setCompareCardsModalState, setCompareStatusModalState, setAssignAudienceModalState, setMobileMenuBarState, setMobileSearchAreaState, setMobileGeneralStatState, setMobileAudienceDetailsState, setActivateCompareAudienceConfirmModalState, setExternalUserManagementModalState, setTopUpDetailsState, setContactUsState, setMetaPlatformDetailsState, setExlPlatformDetailsState } = commonSlice.actions;
export default commonSlice.reducer;