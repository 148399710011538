import { React , useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Typography, Menu, MenuItem, Box} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {getPermission} from "../../../../utils/cryptograpgy";
import RenameAudienceModal from "../../modal/rename-audiencename-modal/RenameAudienceModal";
import styles from "../approve-card/ApproveCard.module.scss";
import {InfoCircle, Location, More, Profile2User} from "iconsax-react";
import {useSelector} from "react-redux";
import Chip from "@mui/material/Chip";
import CrumbService from "../../../../services/api/crumb.service";
import Tooltip from "@mui/material/Tooltip";


function ApproveCard({ audience, index, handleMouseEnter, handleMouseLeave, handleAudienceRename}) {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const navigate = useNavigate();
    const renameCardPermission = getPermission('rename_card');
    const [renameAudienceModalState, setRenameAudienceModalState] = useState(false);
    const { currentTheme } = useSelector((state) => state.theme);
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const [info, setInfo] = useState();
    const extraInfo = getPermission('extra_info');
    const handleAudienceClick = (audience) => {

        navigate('/audience/' + audience.id);

    }

    const handleAudienceRenameClick = () => {
        if (renameCardPermission) {
            setRenameAudienceModalState(true);
        }
    }

    const handleMenuOpen = (event) => {
        event.preventDefault();
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const Info = () => {
        const queryParams = [
            `userId=${user.id}`,
            `countryId=${selectedCountry.id}`,
            `id=${audience.id}`,
        ]
        CrumbService.getCardInfo(queryParams.join('&')).then(
            (data) => {
                setInfo(
                    <>
                        <Box style={{ fontSize: "12px", fontWeight: "bold", width: "190px" }}>
                            Created On &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.createdOn.substring(0, 10).padEnd(12)}<br />
                            Refreshed On &nbsp;:&nbsp;&nbsp;{data.results.modifiedOn.substring(0, 10).padEnd(10)}<br />
                            {extraInfo &&
                                <>
                                    Visit Period &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.visitPeriod.substring(0, 10).padEnd(10)}<br />
                                    Geo Radius &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.geoRadius.substring(0, 10).padEnd(10)}<br />
                                    Week Parting &nbsp;&nbsp;:&nbsp;&nbsp;{data.results.weekParting.substring(0, 10).padEnd(10)}<br />
                                    Day Parting &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.dayParting.substring(0, 15).padEnd(10)}<br />
                                </>
                            }
                        </Box>
                    </>
                );
            },
            (error) => {
                console.log(error);
            }
        );

    };

    return (
        <>
            <Card
                className="card"
                style={{
                    width: '300px',
                    background: currentTheme === 'light' ?  '#ffffff':'#0B0E24',
                    border: currentTheme === 'light' ? '#ffffff' : '1px solid #3955BF',
                    borderRadius:'30px'
                }}
            >
                <Chip style={{ width: "115px" ,marginTop:'10px',marginLeft:'10px', backgroundColor: audience.color }} title={audience.userType} label={audience.userType.length > 18 ? `${audience.userType.substring(0, 18)}...` : audience.userType} component="h1" variant="contained"   />
                <Button disableRipple sx={{  width: 32, height: 32, alignItems: 'center', justifyContent: 'center', borderRadius: '50%', marginLeft: "100px", color : "grey" }} >
                    <Tooltip title={info} arrow onMouseEnter={() => Info()}>
                        <InfoCircle size="32" variant="Bold" />
                    </Tooltip>
                </Button>
                <CardMedia
                    onClick={() => handleAudienceClick(audience)}
                    component="div"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <img
                        src={audience.imagePath}
                        alt="Contemplative Reptile"
                        style={{
                            width: "110px",
                            height: "80px",
                            borderRadius: "12px",
                            opacity: "0px",
                            maxHeight: "100px",
                            objectFit: "cover",
                            margin: "5px 5px"
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: "0 10px" }}>
                        <Typography
                            variant="body1"

                            style={{
                                fontFamily: 'Manrope, Arial, sans-serif',
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '32.78px',
                                textAlign: 'left',
                                color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                            }}
                        >
                            {audience.name}
                        </Typography>
                        <div style={{display: "flex",gap: "5px"}}>
                            <span className={`${styles.ApproveCardLocation ?? ''}`}>
                                <Location sx={{height: "15px",width: "15px"}}/>
                            </span>
                        <Typography
                            variant="body2"
                            style={{
                                fontFamily: 'Manrope, Arial, sans-serif',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '16.39px',
                                textAlign: 'left',
                                color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                            }}
                        >
                            Locations Pin {/*{audience.totalLocationPin}*/}
                            <br/>
                            <Typography
                                variant="body3"
                                style={{
                                    fontSize: '16px',
                                    width: '46px',
                                    height: '19px',
                                    top: '256px',
                                    left: '1223px',
                                    gap: '0px',
                                    opacity: '0px',
                                    color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                                }}
                            >
                                {audience.totalLocationPin}
                            </Typography>
                        </Typography>
                        </div>
                    </div>
                </CardMedia>
                <CardContent className="fiCardContent" onClick={() => handleAudienceClick(audience)}>

                    <Typography
                        variant="body2"
                        className="fiCardContentTextSecondary"
                        component="p"
                        style={{
                            color: currentTheme === 'dark' ? '#ffffff' : undefined, // Font color white if dark theme
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                         <span
                             style={{

                                 borderRadius: '50%', // To make it a circle
                                 padding: '3px', // Adjust as needed for space between the icon and text
                                 marginRight: '8px', // Adjust as needed for space between the icon and text
                                 display: 'inline-flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                                 width: '24px', // Adjust icon container size as needed
                                 height: '24px', // Adjust icon container size as needed
                                 zIndex: 1 // Ensure the icon appears above the circle

                             }}
                         >
                         <Profile2User size="16" />
                        </span>
                        Total Unique Active Users

                    </Typography>
                    <Typography
                        variant="body2"
                        className="fiCardContentTextSecondary"
                        component="p"
                        style={{
                            fontFamily: 'Tomorrow',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '19.2px',
                            textAlign: 'left',
                            marginLeft: '35px',
                            color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                        }}
                    >
                        {audience.totalAudienceCount.toLocaleString()}
                    </Typography>
                </CardContent>
                <CardActions className="fiCardContent" style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleMenuOpen}>
                        <More />
                    </Button>
                </CardActions>
            </Card>

            <Menu
                anchorEl={menuAnchorEl}
                className={`${styles.audienceMenu ?? ''}`}
                open={menuOpen}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                paperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

                        '&.MuiMenu-paper': {
                            borderRadius: '8px',
                            backgroundColor: '#3C4DE7',

                            '*': {
                                color: '#fff',
                                fontSize: '14px',
                                fontWeight: '500',
                                '&:active, &:focus': {
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                }
                            },
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            bottom: '-4px',
                            left: '50%',
                            width: 10,
                            height: 10,
                            bgcolor: '#3C4DE7',
                            transform: 'translateX(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >


                {renameCardPermission &&
                    <MenuItem sx={{ background: 'tranparent' }}>
                        <Button onClick={() => handleAudienceRenameClick()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
                            Edit
                        </Button>
                    </MenuItem>
                }


            </Menu>

            {
                renameCardPermission &&
                <RenameAudienceModal
                    audience={audience}
                    audienceId={audience.id}
                    renameAudienceModalState={renameAudienceModalState}
                    setRenameAudienceModalState={setRenameAudienceModalState}
                    handleAudienceRename={handleAudienceRename}
                />
            }
        </>
            );
}

export default ApproveCard;