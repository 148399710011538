import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import {Checkbox, DialogContentText} from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useDispatch, useSelector} from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {
    setMessageSnackbarStateState,
    setMobileAudienceDetailsState,
} from "../../../../redux/slices/common";
import {useNavigate} from "react-router-dom";
import MetaService from "../../../../services/api/meta.service";
import {setMessage} from "../../../../redux/slices/message";
import LinkIcon from '@mui/icons-material/Link';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

function MetaAddAccountModal({ metaAddAccountModalModalState, setMetaAddAccountModalModalState, onCancel }) {

    const browserWidth = useWindowWidth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [metaAddAccount, setMetaAddAccount] = useState([]);
    const [metaBusinessAccounts, setMetaBusinessAccounts] = useState([]);
    //const [metaAddAccountLink, setMetaAddAccountLink] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const { currentTheme } = useSelector((state) => state.theme);
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = 2;
    const [checked, setChecked] = useState(false);
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setChecked(false);
        let mounted = true;
        if (mounted && user && selectedCountry) {


            const queryParams = [
                `userId=${user.id}`
            ];


            MetaService.metaBusinessAccounts(queryParams.join('&')).then(data => {
                //console.log("API List response: ", data);

                if (data.results && data.results.length > 0) {

                    setMetaBusinessAccounts(data.results);
                    //const selectedBusiness = data.results.find(business => business.isSelected);
                    setSelectedBusiness(data.results[0].id);
                    //setMetaAddAccountLink(selectedAccount ? selectedAccount.tasLink : data.results[0].tasLink);
                    setLoading(false);
                }
            }).catch(error => {
                console.error("Error calling API: ", error);
                setLoading(false);
            });
        }

        return () => {
            mounted = false;
        }
    }, [user, selectedCountry]);

    const fetchMetaAddAccount = () => {
        setLoading(true);
        const queryParams = [
            `userId=${user.id}`
        ];

        MetaService.metaAddAccount(queryParams.join('&')).then(data => {
            if (data.results && data.results.length > 0) {
                setMetaAddAccount(data.results);
                const selectedAccount = data.results.find(account => account.isSelected);
                setSelectedAccount(selectedAccount ? selectedAccount.id : data.results[0].id);
                setLoading(false);
            }
        }).catch(error => {
            console.error("Error calling API: ", error);
            setLoading(false);
        });
    };

    const handleClose = () => {

        setChecked(false);
        setMetaAddAccountModalModalState(false);
        onCancel({ target: { checked: false } });
    };

    const handleConfirm = () => {


        const queryParams = [
            `userId=${user.id}`,
            `addAccountId=${selectedAccount}`,
            `isChecked=${checked}`
        ];

        MetaService.changeAddAccount(queryParams.join('&')).then(data => {
            console.log("API List response: ", data);
            setMetaAddAccount(data.results);
            dispatch(setMessage({
                type: "success",
                title: "FaceBook Add account chosen successfully",
            }));
            dispatch(setMessageSnackbarStateState(true));
            setMetaAddAccountModalModalState(false);
        }).catch(error => {
            setIsShow(true);
            dispatch(setMessage({
                type: "error",
                title: error?.response?.data?.message,
            }));
            dispatch(setMessageSnackbarStateState(true));
        });
    };

    const handleCollapsed = () => {
        //setIsCollapsed(!isCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }

    const handleBusinessRadioChange = (event) => {
        setSelectedBusiness(event.target.value);
    };

    const handleRadioChange = (event) => {
        setSelectedAccount(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let step = activeStep + 1;
        if (step === 1) {

            const queryParams = [
                `userId=${user.id}`,
                `busAccountId=${selectedBusiness}`
            ];

            MetaService.changeBusinessAccount(queryParams.join('&')).then(data => {
                //console.log("API List response: ", data);
                //setMetaAddAccount(data.results);
                // dispatch(setMessage({
                //     type: "success",
                //     title: "FaceBook Add account chosen successfully",
                // }));
                // dispatch(setMessageSnackbarStateState(true));
                //setMetaAddAccountModalModalState(false);
                fetchMetaAddAccount();
            }).catch(error => {
                dispatch(setMessage({
                    type: "error",
                    title: error?.response?.data?.message,
                }));
                dispatch(setMessageSnackbarStateState(true));
            });
        }
    };

    const handleBack = () => {

        setChecked(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const radioColor = currentTheme === 'light' ? '#000' : '#ffffff';

    const handleAcceptTerms = (tasLink) => {
        window.open(tasLink, '_blank');
    };

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <>
            <Dialog
                open={metaAddAccountModalModalState}
                onClose={handleClose}
                PaperProps={{ sx: { width: "600px", borderRadius: "0px" } }}
                sx={{
                    backdropFilter: "blur(2px)",
                }}
            >
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
                            {<KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {<KeyboardArrowLeft />}
                            Back
                        </Button>
                    }
                />
                {!loading ? ( // Conditionally render the content based on loading state
                    <>
                        {/*<DialogTitle>Select a Business and Ad Account</DialogTitle>*/}
                        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3, overflow: "hidden" }}>
                            {activeStep === 0 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center'  }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" style={{ color: currentTheme === 'light' ? '#000' : '#ffffff', paddingBottom: '20px' }}>Select Your Facebook Business</FormLabel>
                                        <RadioGroup
                                            aria-label="facebook-businesses"
                                            name="facebook-businesses"
                                            value={selectedBusiness}
                                            onChange={handleBusinessRadioChange}
                                        >
                                            { metaBusinessAccounts.map(business => (
                                                <FormControlLabel
                                                    key={business.id}
                                                    value={business.id}
                                                    control={<Radio sx={{ color: radioColor, '&.Mui-checked': { color: radioColor } }} />}
                                                    label={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <BusinessCenterIcon
                                                                            sx={{ color: selectedBusiness === business.id ? radioColor : undefined, marginLeft: '8px' }}
                                                            />
                                                            <Typography sx={{ padding: '5px' }}>{business.name}</Typography>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            )}
                            {activeStep === 1 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" style={{ color: currentTheme === 'light' ? '#000' : '#ffffff', paddingBottom: '20px' ,  marginLeft: '50px'}}>Select A Facebook Ad Account</FormLabel>
                                        <RadioGroup
                                            aria-label="facebook-accounts"
                                            name="facebook-accounts"
                                            value={selectedAccount}
                                            onChange={handleRadioChange}
                                        >
                                            { metaAddAccount.map(account => (
                                                <FormControlLabel
                                                    key={account.id}
                                                    value={account.id}
                                                    control={<Radio sx={{ color: radioColor, '&.Mui-checked': { color: radioColor } }} />}
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <span>{account.id}</span>
                                                            {account.tasLink && (
                                                                <>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        sx={{
                                                                            marginLeft: 2,
                                                                            backgroundColor: '#1877F2',
                                                                            color: '#fff',
                                                                            fontSize: '0.75rem', // Adjust font size
                                                                            padding: '4px 8px', // Adjust padding
                                                                            '&:hover': {
                                                                                backgroundColor: '#145dbf',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleAcceptTerms(account.tasLink)}
                                                                    >
                                                                        Accept terms
                                                                    </Button>
                                                                    <Checkbox style={{ display: isShow ?  'flex': 'none' }}
                                                                        checked={checked}
                                                                        onChange={handleCheckboxChange}
                                                                        inputProps={{ 'aria-label': 'Accept terms checkbox' }}
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ padding: "0px" }}>
                            {activeStep === 0 && (
                                    <Button variant='contained' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                            )}
                            {activeStep === 1 && (
                                <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                                    <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                                    <Button className="form-button" variant='contained' onClick={handleConfirm} sx={{ textTransform: "none", borderRadius: "0px" }}>Save</Button>
                                </ButtonGroup>
                            )}
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <DialogContentText sx={{ color: currentTheme === 'light' ? '#000' : '#ffffff' }}>Loading...</DialogContentText>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}

export default MetaAddAccountModal;