import { React , useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Typography,  Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {getPermission} from "../../../../utils/cryptograpgy";
import {Location, More, Profile2User} from "iconsax-react";
import {useDispatch, useSelector} from "react-redux";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import {baseUrl} from "../../../../config/config";
import {Box} from "@mui/system";
import MetaAddAccountModal from "../../modal/meta-add-account-modal/MetaAddAccountModal";
import TopUpDetails from "../../sidebar/top-up-details-sidebar/TopUpDetails";
import MetaPlatformDetails from "../../sidebar/meta-platform-details-confirm-sidebar/MetaPlatformDetails";
import EXLPlatformDetails from "../../sidebar/exl-platform-details-confirm-sidebar/EXLPlatformDetails";
import {
    setMobileAudienceDetailsState,
    setMetaPlatformDetailsState,
    setExlPlatformDetailsState
} from "../../../../redux/slices/common";

const ConnectorCard = ({ connectorData, onChange, filteredConnectorData }) => {

    const navigate = useNavigate();
    const renameCardPermission = getPermission('rename_card');
    //const [renameAudienceModalState, setRenameAudienceModalState] = useState(false);
    const { currentTheme } = useSelector((state) => state.theme);
    const [metaAddAccountModalModalState, setMetaAddAccountModalModalState] = useState(false);
    const [exlModalModalState, setExlModalModalState] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isExlCollapsed, setIsExlCollapsed] = useState(false);
    const dispatch = useDispatch();

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#06e60d',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#39BF8F' : '#f1f8f1',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#353535' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleOnChange = (event) => {

        const isChecked = event.target.checked;

        const updatedData = filteredConnectorData.map(connector => {
            if (connector.id === connectorData.id) {

                if(connector.id === 2 && isChecked === true) {

                    handleMetaPlatFormDetailsClick();
                    //setMetaAddAccountModalModalState(true);
                } else if (connector.id === 4 && isChecked === true) {

                    handleExePlatFormDetailsClick();
                }

                if(isChecked === false && connector.id === 2) {

                    setIsCollapsed(false);
                }

                if(isChecked === false && connector.id === 4) {

                    setIsExlCollapsed(false);
                }

                return { ...connector, isOn: isChecked };
            }
            return connector;
        });

        onChange(updatedData);
    }

    const handleMetaChange = () => {

        const updatedData = filteredConnectorData.map(connector => {
            if (connector.id ===2) {

                return { ...connector, isOn: true };
            }
            return connector;
        });
        onChange(updatedData);
        setMetaAddAccountModalModalState(false);
    }

    const handleExlChange = () => {

        const updatedData = filteredConnectorData.map(connector => {
            if (connector.id === 4) {

                return { ...connector, isOn: true };
            }
            return connector;
        });
        onChange(updatedData);
        setExlModalModalState(false);
    }

    const handleCollapsed = () => {

        const updatedData = filteredConnectorData.map(connector => {
            if (connector.id === 2) {

                return { ...connector, isOn: false };
            }
            return connector;
        });

        onChange(updatedData);

        setIsCollapsed(!isCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }

    const handleExlCollapsed = () => {

        const updatedData = filteredConnectorData.map(connector => {
            if (connector.id === 4) {

                return { ...connector, isOn: false };
            }
            return connector;
        });

        onChange(updatedData);

        setIsExlCollapsed(!isExlCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }

    const handleMetaPlatFormDetailsClick = () => {
        setIsCollapsed(!isCollapsed);
        setMetaAddAccountModalModalState(true);
        dispatch(setMetaPlatformDetailsState(true));
    }

    const handleExePlatFormDetailsClick = () => {

        setIsExlCollapsed(!isExlCollapsed);
        setExlModalModalState(true);
        dispatch(setExlPlatformDetailsState(true));
    }

    const handleClosePlatFormDetailsClick = () => {
        setIsCollapsed(false);
        dispatch(setMetaPlatformDetailsState(false));
    }

    return (
        <>
            <Card
                className="card"
                style={{
                    width: '270px',
                    height: '180px',
                    borderRadius: '11px',
                    background: currentTheme === 'light' ?  '#ffffff':'#0B0E24',
                    border: currentTheme === 'light' ? '1px solid #D9D9D9' : '1px solid #212330',
                }}
            >
                <CardMedia
                    component="div"
                    title= {connectorData.isActive ? 'Connected' : 'Not Connected'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        width: '100%', // Ensure the CardMedia takes full width
                        height: '110px', // Set the height of CardMedia
                    }}
                    onClick={connectorData.isAuthorized ? null : () => navigate('/user-profile')}
                >
                    {/* Image */}
                    <img
                        src={baseUrl + connectorData.imageUrl}
                        alt="Contemplative Reptile"
                        style={{
                            backgroundColor: 'white',
                            width: '100%', // Fill the width of CardMedia
                            height: '100%', // Fill the height of CardMedia
                            objectFit: connectorData.id === 3 ? 'cover' : 'none', // Cover the CardMedia area
                            position: 'absolute', // Position the image absolutely
                            top: '0', // Align the image to the top
                            left: '0', // Align the image to the left
                        }}
                    />

                    {/* Connected status */}
                        <Box
                            style={{
                                position: 'absolute',
                                right: '-15px',
                                bottom: '90%',
                                transform: 'translate(-50%, -50%)', // Center the text
                                color: connectorData.isAuthorized ? '#39BF8F' : 'rgb(216,2,2)', // Text color
                                padding: '2px 5px', // Add padding to the text
                                fontSize: '105px',
                            }}
                            title= {connectorData.isAuthorized ? 'Connected' : 'Not Connected'}
                        >
                            .
                        </Box>
                </CardMedia>
                <CardContent className="fiCardContent" style={{ lineHeight: "27.32px", fontSize: "20px", display: 'flex', backgroundColor: currentTheme === 'light' ?  '#F1F1F1':'#0B0E24', color: currentTheme === 'light' ? '#000000' : "white", height: "100px", fontWeight: "500", fontFamily: "Manrope, Arial, sans-serif" }}
                             onClick={connectorData.isAuthorized ? null : () => navigate('/user-profile')}>

                    <div style={{ marginRight: "auto" }}>
                        {connectorData.name}
                    </div>
                    <IOSSwitch disabled={!connectorData.isAuthorized} sx={{ marginRight: 0, width: "44px" }} onChange={handleOnChange}  checked={connectorData.isOn}/>
                </CardContent>
            </Card>

            { metaAddAccountModalModalState &&

                <MetaPlatformDetails
                    isCollapsed={isCollapsed}
                    handleCollapsed={handleCollapsed}
                    handleMetaChange={handleMetaChange}
                />
            }

            { exlModalModalState &&

                <EXLPlatformDetails
                    isExlCollapsed={isExlCollapsed}
                    handleExlCollapsed={handleExlCollapsed}
                    handleExlChange={handleExlChange}
                />
            }
        </>
    );
}

export default ConnectorCard;